// 把文件的数据改为自己的数据
export function changefiledata(res){
  let changefiledatas = [];
  res.data.content.forEach((item) => {
    let {
      id,
      fileName,
      suffix,
      createdTime,
      targetId,
      status
    } = item;
    let changefiledata = {
      regulatetitle:fileName,
      filesuffix : suffix,
      releasetime: createdTime,
      regulateId : id,
      status,
      regulatecontent:
            targetId==null ? "此处为该文件下载的文件简介，如果需要添加简介，可登录后台，进行添加描述处理" : targetId,
    }
    changefiledatas.push(changefiledata);
  })
  return changefiledatas;
}
