<template>
  <div class="policyrule-page">
    <header class="policyrule-header">
      <div>资源下载/<span>download</span></div>
      <span style="cursor: pointer" @click="toDownload()">>></span>
    </header>
    <div class="resouce-img">
      <img :src="imgSrc" />
    </div>
    <ul class="policyrule-list">
      <li v-for="item in regulateData" :key="item.regulateId">
        <a @click="handlereguclick" class="policyrulea">{{
          item.regulatetitle
        }}</a>
        <time class="policyruletime">{{ item.releasetime }}</time>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";
import { changefiledata } from "../../utils/lchangedata.js";

export default {
  name: "Policyrule",
  data() {
    return {
      regulateData: [],
      imgSrc: "",
    };
  },
  mounted() {
    axios.get("/api/api/file/list?pageNo=1&pageSize=5").then((res) => {
      let filedatas = changefiledata(res);
      this.regulateData.splice(0, this.regulateData.length);
      filedatas.forEach((item) => {
        this.regulateData.push(item);
      });
    });
    // 回去图片路径
    axios.get("/api/image/list?category=7").then((res) => {
      this.imgSrc= `http://106.55.230.35${res.data.content[4].url}`
    });
  },
  methods: {
    toDownload() {
      this.$router.push({
        path: "/regulation",
      });
    },
    handlereguclick() {
      this.$router.push({
        path: `/regulation`,
      });
    },
  },
};
</script>

<style>
/* .policyrule-page{
  width: 400px;
} */
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
li {
  margin: 0;
  padding: 0;
}
a {
  color: #bd0c15;
  text-decoration: none;
}
.policyrule-page .policyrule-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #bd0c15;
  border-bottom: 2.5px solid #bd0c15;
  padding: 5px;
  margin-bottom: 15px;
}
.policyrule-page .policyrule-header a:hover {
  color: #666666;
}
.policyrule-page .policyrule-header div {
  font-size: 24px;
}
.policyrule-page .policyrule-header div span {
  font-size: 14px;
  color: #666666;
  /* font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
}

.policyrule-list {
  margin-top: 15px;
  border-top: 1px solid rgb(235, 235, 235);
}

.policyrule-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  font-size: 14px;
}
.policyrule-list li a {
  color: #666666;
  padding: 11.3px 0;
}
.policyrule-list li a:hover {
  color: #bd0c15;
}
.policyrule-list li time {
  color: #8b8787;
}
.resouce-img {
  margin-top: 40px;
}
.resouce-img img {
  width: 100%;
  height: 170px;
  object-fit: cover;
}
.policyrulea {
  display: inline-block;
  font-size: 14px;
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.policyruletime {
  width: 77px;
  overflow: hidden;
  white-space: nowrap;
}
</style>