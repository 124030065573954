<template>
  <div id="app">
    <el-container class="app-container">
      <callingheader></callingheader>
      <el-header style="border: none;z-index:1000">
        <navigation />
      </el-header>
      <el-main>
        <SlideShow v-if="topage" />
        <Otherimg v-else-if="routerimg != null" :id="routerimg" />
        <div class="main-container">
          <!-- 面包屑 -->
          <Breadcrumb />
          <router-view></router-view>
        </div>
      </el-main>

      <el-footer>
        <!-- 友情链接 -->
        <links></links>
        <footerr></footerr>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import navigation from "./components/navigation.vue";
import SlideShow from "./components/slideshow.vue";
import Breadcrumb from "./components/breadcrumb.vue";
import footerr from "./components/Footer.vue";
import callingheader from "./components/callingheader.vue";

// 图片页面
import Otherimg from "./components/blankCpn/otherimg.vue";
import Links from './components/links.vue';
export default {
  components: {
    navigation,
    footerr,
    SlideShow,
    Breadcrumb,
    callingheader,
    Otherimg,
    Links,
  },
  computed: {
    topage: function () {
      return this.$route.path == "/";
    },
    routerimg: function () {
      if (this.$route.path == "/companyProfile") {
        return 0;
      } else if (this.$route.path == "/bidding") {
        return 1;
      } else if (this.$route.path == "/bidwinning") {
        return 2;
      } else if(this.$route.path == "/changeBidding"){
        return 3;
      }
      else if(this.$route.path == "/announcement"){
        return 4;
      }
      else if (this.$route.path == "/political") {
        return 5;
      } else if(this.$route.path == "/regulation"){
        return 6;
      }
      else if (this.$route.path == "/contact") {
        return 7;
      } else {
        return null;
      }
    },
  },
};
</script>
<style scoped>
@import "./assets/css/common.css";
body {
  margin: 0;
  padding: 0;
}
.app-container .el-header {
  padding: 0;
}
.app-container .el-main {
  padding: 0;
  margin: 0;
}
.main-container {
  width: 1100px;
  margin: 0 auto;
}
</style>
