<template>
  <div class="calling-footer">
    <div class="footer-content">
      <ul class="footer-top">
        <li class="footer-font-grey footer-center">
          <i class="el-icon-phone-outline footer-icon"></i
          ><span> 电话：{{tel}} </span>
          <i class="el-icon-message footer-icon"></i
          ><span>邮箱: {{email}}</span>
        </li>
        <li class="footer-font-grey">
          <p>
            {{text}}
          </p>
        </li>
        <li class="footer-font-light">
          <p>© 江西景阳招标咨询有限公司 All rights reserve</p>
          <p class="footer-p">
            工信部备案编号：<a href="https://beian.miit.gov.cn/"
              >赣ICP备2021011758号</a
            >
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "callingFooter",
  data() {
    return {
      companyInfo: [],
      tel:'',
      email:'',
      text:''
    };
  },
  mounted() {
    axios.get("/api/info/list").then((res) => {
      this.companyInfo = res.data.content;
      this.email = this.companyInfo[0].content;
      this.tel = this.companyInfo[1].content;
      this.text = this.companyInfo[2].content;
    });
  },
};
</script>

<style scoped>
.calling-footer {
  position: relative;
  width: 100%;
  margin: 0px auto;
  margin-top: 1.5rem;
  z-index: 1;
  background-color: rgb(51, 51, 51);
}
.footer-content {
  width: 70%;
  margin: 0rem auto;
}
.footer-content ul li {
  list-style: none;
  text-align: center;
  padding-bottom: 0.6rem;
}
.footer-icon {
  font-size: 2rem;
  padding-left: 1rem;
  padding-right: 0.5rem;
  position: relative;
  top: 0.5rem;
}
.footer-font-grey {
  font-size: 0.9rem;
  color: rgb(101, 101, 101);
}
.footer-font-light {
  color: rgb(254, 254, 254);
}
.footer-top {
  padding-top: 2rem;
}
.footer-p {
  font-size: 0.9rem;
}
.footer-p a {
  color: rgb(254, 254, 254);
}
</style>