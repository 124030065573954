<template>
  <div class="aboutus-com">
    <header class="aboutus-header">
      <div>关于我们/<span>about us</span></div>
      <a href="#">>></a>
    </header>
    <ul class="aboutus-icon-list">
      <router-link to="/companyProfile" class="router-link">
        <li class="icon-list">
          <i class="el-icon-s-data icon"></i>公司简介
        </li></router-link
      >
      <router-link to="/bidding" class="router-link"
        ><li class="icon-list">
          <i class="el-icon-s-help icon"></i>招标公告
        </li></router-link
      >
      <router-link to="/bidwinning" class="router-link"
        ><li class="icoon-list">
          <i class="el-icon-folder-opened icon"></i>中标公告
        </li></router-link
      >
      <router-link to="/political" class="router-link"
        ><li class="icon-list">
          <i class="el-icon-user-solid icon"></i>政策法规
        </li></router-link
      >
      <router-link to="/regulation" class="router-link"
        ><li class="icon-list">
          <i class="el-icon-tickets icon"></i>下载中心
        </li></router-link
      >
            <router-link to="/changeBidding" class="router-link"
        ><li class="icon-list">
          <i class="el-icon-refresh icon"></i>变更公告
        </li></router-link
      >
            <router-link to="/announcement" class="router-link"
        ><li class="icon-list">
          <i class="el-icon-message icon"></i>信息公告
        </li></router-link
      >
      <router-link to="/contact" class="router-link"
        ><li class="icon-list">
          <i class="el-icon-present icon"></i>联系我们
        </li></router-link
      >
    </ul>
  </div>
</template>

<script>
export default {
  name: "Aboutus",
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
li {
  margin: 0;
  padding: 0;
}

.aboutus-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #bd0c15;
  border-bottom: 2.5px solid #bd0c15;
  padding: 5px;
  margin-bottom: 30px;
}
.aboutus-header a:hover {
  color: #666666;
}
.aboutus-header div {
  font-size: 24px;
}
.aboutus-header div span {
  font-size: 14px;
  color: #666666;
}
.aboutus-icon-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.aboutus-icon-list li {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgb(235, 234, 234);
  padding: 10px;
  margin: 10px 0;
}
.icon {
  /* border: 1px solid rgb(235, 234, 234);
   padding: 30px; */
  margin: 10px 0;
  font-size: 50px;
  color: #666666;
}
.aboutus-icon-list li {
  font-size: 14px;
}
.router-link {
  text-decoration: none;
  color: #666666;
}
</style>