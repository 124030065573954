<template>
  <div class="company-com">
    <header class="company-header">
      <div>企业展示/<span>elegant demeanour</span></div>
      <a href="#">>></a>
    </header>
    <ul class="showcompany-list">
      <li class="company-list" v-for="item in companyimglist" :key="item.src">
        <img :src="item.src" />
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios"
export default {
  data() {
    return {
      companyimglist: [
        {
          src: "234324",
        },
      ],
    };
  },
  mounted() {
    axios.get("http://106.55.230.35:8899/image/list?category=2").then((res) => {
      this.companyimglist.splice(0, this.companyimglist.length);
      for (let i = 0; i < res.data.content.length; i++) {
        this.companyimglist.push({
          src: `http://106.55.230.35${res.data.content[i].url}`,
        });
      }
    });
  },
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
li {
  margin: 0;
  padding: 0;
}

.company-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #bd0c15;
  border-bottom: 2px solid #bd0c15;
  padding: 5px;
  margin-bottom: 40px;
  margin-top: 25px;
  font-size: 24px;
}
.company-header div span {
  font-size: 14px;
  color: #666666;
  /* font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
}
.company-header a:hover {
  color: #666666;
}
.showcompany-list {
  display: flex;
  justify-content: space-around;
  overflow-x: auto;
}
.showcompany-list .company-list img {
  width: 210px;
  height: 230px;
  object-fit: cover;
}
</style>