<template>
  <div class="cpn-links">
    <span class="link-title">友情链接： </span>
    <span v-for="item in links" :key="item.linkId">
      <a :href="item.linkUrl" target="_blank" class="link-item overhide">{{
        item.linkContent
      }}</a>
    </span>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "",
  data() {
    return {
      links: [],
    };
  },
  created() {
    //获取所有链接
    axios.get("/api/link/list", {}).then((res) => {
      let content = res.data.content;
      for (let i = 0; i < content.length; i++) {
        this.links.push({
          linkId: content[i].id,
          linkContent: content[i].name,
          linkUrl: content[i].url,
        });
      }
    });
  },
};
</script>

<style scoped>
.cpn-links {
  width: 90%;
  margin: 0 auto;
  margin-top: 30px;
  font-size: 13px;
  text-align: center;
  color: rgb(102, 102, 102);
}
.link-title {
  margin-right: 3.7%;
}
.link-item {
  color: rgb(102, 102, 102);
}
.link-item:hover {
  color: rgb(189, 12, 21);
}
.overhide {
  width: 15%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>