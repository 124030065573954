import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, { ak: 'M7b7G7t86YN6UNM8tzNt1By6ofoACYkx' })

Vue.use(ElementUI);

// new Vue({
//   el: '#app',
//   render: h => h(App)
// });
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
