<template>
  <div class="pageDrtails">
    <p class="pageDrtails-title">{{ pageDrtails.title }}</p>
    <div v-html="pageDrtails.content"></div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "",
  data() {
    return {
      pageDrtails: {
        title: "",
        content: ``,
      },
    };
  },
  created() {
    this.$store.state.navIndex = "/political";
    //进入编辑页的标识
    sessionStorage.setItem("detailP", true);
    //进入编辑页的标识（关键字）
    sessionStorage.setItem("ifInputP", true);
  },
  mounted() {
    axios.get("/api/bulletin/view/" + this.$route.query.id).then((res) => {
      let page = res.data.content;
      this.pageDrtails.title = page.title;
      this.pageDrtails.content = page.content;
    });
  },
};
</script>

<style scoped>
.pageDrtails {
  width: 80%;
  margin: 0 auto;
}
.pageDrtails-title {
  font-size: 28px;
  text-align: center;
}

/* ul ol 样式 */
::v-deep ol {
  margin: 10px 0 10px 22px;
  list-style-type: decimal;
}
::v-deep ul {
  margin: 10px 0 10px 22px;
  list-style-type: disc;
}
/* table 样式 */
::v-deep table {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
::v-deep table td,
::v-deep table th {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 3px 5px;
  height: 32px;
}
::v-deep table th {
  border-bottom: 2px solid #ccc;
  text-align: center;
  background-color: #f1f1f1;
}

/* blockquote 样式 */
::v-deep blockquote {
  display: block;
  border-left: 8px solid #d0e5f2;
  padding: 5px 10px;
  margin: 10px 0;
  line-height: 1.4;
  min-height: 24px;
  font-size: 100%;
  background-color: #f1f1f1;
}

/* code 样式 */
::v-deep code {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 3px 5px;
  margin: 0 3px;
}
::v-deep pre code {
  display: block;
}
::v-deep a {
  text-decoration: underline;
  color: #5592e5;
}
</style>