<template>
  <div class="wbidding-cpn">
    <div class="wbidding-cpn-header change-bid">
      <span class="common-header-big">变更公告/</span>
      <span class="header-cpn-small common-header-small">changebid</span>
      <span class="header-cpn-right"><a href="/changeBidding">>></a></span>
    </div>
    <div class="wbidding-cpn-main common-main">
      <img :src="imgSrc" alt="" class="image-padding" />
      <ul class="wbidding-ul">
        <li
          v-for="item in wbiddingDatas"
          :key="item.pid"
          class="wbidding-cpn-main-pages"
        >
          <a href="" @click="pageDetail(item.pid)" class="overhide">
            {{ item.ptitle }}
          </a>
          <span>
            {{ item.creationTime }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "",
  data() {
    return {
      wbiddingDatas: [],
      imgSrc : ""
    };
  },
  methods: {
    pageDetail(id) {
      this.$router.push({
        path: `/changeBiddingDetails?id=${id}`,
      });
    },
  },
  mounted() {
    axios.get("/api/image/list?category=7").then((res) => {
      this.imgSrc= `http://106.55.230.35${res.data.content[1].url}`
    });

    //创建时，获取第一页（最新）数据
    axios
      .get("http://106.55.230.35:8899/bulletin/list", {
        params: {
          pageNo: 1,
          pageSize: 5,
          search: "",
          type: "变更公告",
        },
      })
      .then((res) => {
        let list = res.data.content.list;
        for (let i = 0; i < list.length; i++) {
          this.wbiddingDatas.push({
            pid: list[i].id,
            ptitle: list[i].title,
            creationTime: list[i].createTime.slice(0, 10),
          });
        }
      });
  },
};
</script>

<style>
.change-bid{
  margin-top: 2.92rem;
}
.wbidding-cpn-header {
  position: relative;
  display: flex;
  color: #bd0c15;
  border-bottom: 2.5px solid #bd0c15;
  padding: 5px;
  margin-bottom: 40px;
}
.wbidding-ul {
  border-top: 1px solid rgb(235, 234, 234);
}
.header-cpn-big {
  color: rgb(189, 12, 21);
  font-size: 26px;
}
.header-cpn-small {
  padding-top: 9px;
  color: rgb(102, 102, 102);
}
.header-cpn-right {
  position: absolute;
  right: 0;
  top: 10px;
}
.header-cpn-right a {
  color: rgb(189, 12, 21);
}
.header-cpn-right a:hover {
  color: rgb(102, 102, 102);
}
.wbidding-cpn-main-pages {
  padding: 10px 0;
  font-size: 14px;
}
.wbidding-cpn-main-pages span {
  float: right;
  color: #8b8787;
}
.wbidding-cpn-main-pages a {
  color: #666666;
}
.wbidding-cpn-main-pages a:hover {
  color: rgb(189, 12, 21);
}
.image-padding {
  width: 100%;
  height: 170px;
  object-fit: cover;
  padding-bottom: 15px;
}
.overhide {
  width: 50%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>