<template>
  <div class="other-img">
    <img :src="imgdata[id]" />
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Otherimg",
  props: {
    id: Number,
  },
  mounted() {
    axios.get("/api/image/list?category=6").then((res) => {
      this.imgdata.splice(0, this.imgdata.length);
      for (let i = 0; i < res.data.content.length; i++) {
        this.imgdata.push( `http://106.55.230.35${res.data.content[i].url}`,);
      }
    });
  },
  data() {
    return {
      imgdata: [],
    };
  },
};
</script>

<style>
.other-img img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}
</style>