<template>
  <div class="nav">
    <el-menu
      router
      :default-active="navIndex"
      mode="horizontal"
      height="50px"
      background-color="#333333"
      text-color="#fff"
      active-text-color="#F75959"
      class="navigation"
    >
      <el-menu-item index="/">首页</el-menu-item>
      <el-menu-item index="/companyProfile">公司简介</el-menu-item>
      <!-- <el-submenu index="e"> -->
        <!-- <template slot="title">招标中标</template> -->
      <el-menu-item index="/bidding">招标公告</el-menu-item>
      <el-menu-item index="/bidwinning">中标公告</el-menu-item>
      <!-- </el-submenu> -->
      <el-menu-item index="/changeBidding">变更公告</el-menu-item>
      <!-- <el-submenu index="announcement"> -->
        <!-- <template slot="title">政治法规</template> -->
        <el-menu-item index="/announcement">信息公告</el-menu-item>
        <el-menu-item index="/political">政策法规</el-menu-item>
      <!-- </el-submenu> -->
      <el-menu-item index="/regulation">下载中心</el-menu-item>
      <el-menu-item index="/contact">联系我们</el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "navigation",
  data() {
    return {
      activeIndex: this.$store.state.navIndex,
    };
  },
  computed:{
    navIndex:function(){
      return this.$store.state.navIndex
    }
  },
  mounted(){
    let nav = document.querySelector('.nav')
    window.addEventListener('scroll',function(){
      if(window.pageYOffset-80 > 0){
        nav.style.position = 'fixed'
        nav.style.top = '0px'
        nav.style.width = '100%'
      }else{
        nav.style.position = 'static'
        
      }
    })

  }
};
</script>

<style scoped>
.navigation {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  border: none;
  margin-bottom: 100px;
}

.navigation li {
  display: block;
  text-align: center;
  width: 100%;
}
.el-menu-item {
  font-size: 17px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
</style>