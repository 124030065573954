<template>
  <div class="header">
    <div class="left">
      <div class="img-box">
        <img src="../assets/logo.jpg">
      </div>
      <div class="write">{{name}}</div>
    </div>
    <a href="/contact"><el-button plain>联系我们</el-button></a>
    <div class="right">
      <div class="phone">
        <img src="../assets/phone.png">
      </div>
      <div class="text">
        <div class="consult">{{consult}}</div>
        <div class="telephone-number">{{telephone}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'callingheader',
  data(){
        return{
            name:"江西景阳招标咨询有限公司",
            consult:"咨询服务热线：",
            telephone:"0791-83831221"
        }
  },
  created(){
    axios.get('http://106.55.230.35:8899/info/list').then(
      res=>{
        this.telephone = res.data.content[1].content
      }
    )
  }

}
</script>

<style scoped>
  .header {
    width: 100%;
    height: 80px;
    overflow: hidden;
  }
  .left{
    margin-top: 1rem;
  
  }
  .right {
    float: right;
    margin-right: 2rem;
  }
  .img-box img {
    width: 4rem;
    height: 4rem;
  }
  .img-box {
    width: 4rem;
    height: 4rem;
    float: left;
    margin-left: 8rem;
  }
  .write {
    float: left;
    margin-left: 1.5rem;
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 1rem;
    color: rgb(35,26,21);
  }
  .phone {
    width: 4rem;
    height: 4rem;
    float: left;
    /* margin-top: 1rem; */
  }
  .text {
    float: left;
  }
  .consult {
    font-weight: 500;
    margin-top: 0.2rem;
  }
  .telephone-number {
    font-weight: 600;
    /* margin: 0; */
    margin-top: 0.2rem;
    color: rgb(127, 0, 0);
  }
  .el-button.is-plain {
    float: left;
    margin-left: 26rem;
    margin-top: 0.4rem;
  }
  .el-button.is-plain:hover {
    color: rgb(127, 0, 0);
    border-color: rgb(127, 0, 0);
    /* background-color: rgb(127, 0, 0); */
  }
</style>