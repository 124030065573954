import vue from 'vue'
import Vuerouter from 'vue-router'
import blank from '../views/blank.vue'
import bidwinning from "../views/bidwinning/BidWinning.vue"
import BwPageDetail from "../views/bidwinning/BWpageDetail.vue"
import notice from "../views/notice.vue"
import contact from "../views/contact.vue"
import announcement from "../views/announcement/announcement.vue"
import announcementDetails from "../views/announcement/announcementDetails.vue"
import political from "../views/political/political.vue"
import politicalDetails from "../views/political/politicalDetails.vue"
import changeBidding from "../views/changeBidding/changeBidding.vue"
import changeBiddingDetails from "../views/changeBidding/changeBiddingDetails.vue"
vue.use(Vuerouter)
const routes = [
  {
    path: '/',
    component: blank
  },
  {
    path: "/companyProfile",
    component: () => import("../views/companyProfile.vue"),
    name: "公司概况"
  },
  {
    path: "/bidding",
    component: () => import("../views/bidding/bidding.vue"),
    name: "招标公告"
  },
  {
    path: '/BiddingDetail/',
    component: () => import("../views/bidding/biddetail.vue"),
  },
  {
    path: "/regulation",
    component: () => import("../views/regulation/Regulations.vue"),
    name: "下载中心"
  },
  {
    path: "/bidwinning",
    component: bidwinning,
    name: "中标公告",
  },
  // {
  //   path: '/pageDrtails/',
  //   component: () => import("../views/bidwinning/BWpageDetail.vue"),
  //   name: "中标公告",
  // },
  {
    path: "/BwPageDetail",
    component: BwPageDetail,
  }
  ,
  {
    path: "/announcement",
    component: announcement,
    name: "信息公告",
  },
  {
    path: "/announcementDetails",
    component: announcementDetails,
  },
  {
    path: "/political",
    component: political,
    name: "政策法规",
  },
  {
    path: "/politicalDetails",
    component: politicalDetails,
  },
  {
    path: "/changeBidding",
    component: changeBidding,
    name: "变更公告",
  },
  {
    path: "/changeBiddingDetails",
    component: changeBiddingDetails,
  },
  {
    path: "/notice",
    component: notice,
    name: "公告"
  },
  {
    path: "/contact",
    component: contact,
    name: "关于我们"
  },
]
const router = new Vuerouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
export default router
