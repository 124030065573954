<template>
  <div class="breadcrumb">
    <el-breadcrumb separator=">>" v-if="ishomepage">
      <el-breadcrumb-item :to="{ path: '/' }" class="breadcrumb-item"
        ><a href="/" class="el-icon-s-promotion"></a
        ><a href="/" class="breadcrumb-font">首页</a></el-breadcrumb-item
      >
      <el-breadcrumb-item class="breadcrumb-font">{{
        this.$route.name
      }}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  computed: {
    ishomepage: function () {
      return this.$route.path === "/bidding" ||
        this.$route.path === "/regulation" ||
        this.$route.path === "/bidwinning" ||
        this.$route.path === "/contact" ||
        this.$route.path === "/changeBidding" ||
        this.$route.path === "/companyProfile" ||
        this.$route.path === "/changeBidding" ||
        this.$route.path == "/political" ||
        this.$route.path == "/announcement"
        ? true
        : false;
    },
  },
};
</script>



<style scoped>
.breadcrumb {
  margin: 20px 10px;
}
.el-breadcrumb {
  display: flex;
  align-items: center;
}
.breadcrumb-font {
  color: #333333;
  font-size: 14px;
}
.breadcrumb-item a {
  color: #333333;
  font-size: 14px;
}
.breadcrumb-item a:hover {
  color: rgb(189, 12, 21);
  font-size: 14px;
}
</style>