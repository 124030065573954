<template>
  <div class="home-page">
    <el-row :gutter="30">
      <el-col :span="8"
        ><div class="grid-content bg-purple"><Bidding /></div>
      </el-col>
      <el-col :span="8"
        ><div class="grid-content bg-purple"><changeBid /></div>
      </el-col>
      <el-col :span="8"
        ><div class="grid-content bg-purple-light"><introduce /></div
      ></el-col>
    </el-row>
    <el-row :gutter="30">
      <el-col :span="8"
        ><div class="grid-content bg-purple"><bid-winnings /></div
      ></el-col>
      <el-col :span="8"
        ><div class="grid-content bg-purple-light"><Policyrules /></div
      ></el-col>
      <el-col :span="8"
        ><div class="grid-content bg-purple"><Aboutus /></div
      ></el-col>
    </el-row>
    <el-row>
  <el-col :span="24"><div class="grid-content bg-purple-dark"><Showcompany/></div></el-col>
</el-row>
  </div>
</template>

<script>
import BidWinnings from "../components/blankCpn/BidWinnings.vue";
import Policyrules from "../components/blankCpn/Policyrules.vue";
import Bidding from "../components/blankCpn/Bidding.vue";
import Aboutus from "../components/aboutus.vue";
import Introduce from "../components/blankCpn/Introduce";
import Showcompany from "../components/blankCpn/showcompany.vue"
import changeBid from "../components/blankCpn/changeBid.vue"

export default {
  name: "blank",
  components: {
    Policyrules,
    BidWinnings,
    Bidding,
    Aboutus,
    Introduce,
    Showcompany,
    changeBid
  },
};
</script>

<style scoped>
.home-page .el-row{
  margin-bottom: 40px;
}
</style>