<template>
  <div class="wbidding-page">
    <div class="wbidding-announce">
      <div class="wbidding-header">
        <div>
          <span class="header-big">变更公告/</span>
          <span class="header-small">change bid winning announcement</span>
        </div>
        <div class="bid-search">
          <el-input
            v-model="inputkey"
            placeholder="请输入关键字"
            size="small"
            suffix-icon="el-icon-search"
            @input="bidsearchkey"
            >请输入关键字</el-input
          >
        </div>
      </div>
      <div class="wbidding-main">
        <div class="bw-pic"></div>
        <div v-for="item in wbiddingData.wbiddingDatas" :key="item.pid">
          <a href="" class="wbidding-main-a" @click="pageDetail(item.pid)">
            <span class="overhide">{{ item.ptitle }}</span>
          </a>
          <div class="wbidding-main-time">
            <span>
              {{ item.creationTime }}
            </span>
          </div>
        </div>
      </div>
      <div class="wbidding-footer">
        <el-pagination
          background
          :layout="bwLayout"
          :current-page="wbiddingPagination.currentPages"
          :total="wbiddingData.totalPage"
          :page-size="wbiddingPagination.pageSize"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "",
  comment: {},
  data() {
    return {
      wbiddingPagination: {
        //当前页数
        currentPages: 1,
        //当前每页数据条数
        pageSize: 10,
        bwPicUrl: "",
      },
      wbiddingData: {
        //总条数
        totalPage: 0,
        wbiddingDatas: [],
      },
      inputkey: "",
    };
  },
  methods: {
    handleCurrentChange(index) {
      //页面改变，发送请求获取新数据
      //this.$store.state.zhongCurrentPage = index;
      this.wbiddingPagination.currentPages = index;
      //将当前页数保存进session
      sessionStorage.setItem(
        "currentPageC",
        this.wbiddingPagination.currentPages
      );
      // console.log(sessionStorage);
      //页数改变，重新发送请求
      axios
        .get("/api/bulletin/list", {
          params: {
            pageNo: this.wbiddingPagination.currentPages,
            pageSize: this.wbiddingPagination.pageSize,
            search: this.inputkey,
            type: "变更公告",
          },
        })
        .then((res) => {
          let list = res.data.content.list;
          this.wbiddingData.totalPage = parseInt(res.data.content.total);
          this.wbiddingData.wbiddingDatas = [];
          for (let i = 0; i < list.length; i++) {
            this.wbiddingData.wbiddingDatas.push({
              pid: list[i].id,
              ptitle: list[i].title,
              creationTime: list[i].createTime,
            });
          }
        });
    },

    // 搜索关键字
    bidsearchkey() {
      //将当前关键字保存进session
      sessionStorage.setItem("inputkeyC", this.inputkey);
      axios
        .get("/api/bulletin/list", {
          params: {
            pageNo: 1,
            pageSize: this.wbiddingPagination.pageSize,
            search: this.inputkey,
            type: "变更公告",
          },
        })
        .then((res) => {
          let list = res.data.content.list;
          this.wbiddingData.totalPage = parseInt(res.data.content.total);
          this.wbiddingData.wbiddingDatas = [];
          for (let i = 0; i < list.length; i++) {
            this.wbiddingData.wbiddingDatas.push({
              pid: list[i].id,
              ptitle: list[i].title,
              creationTime: list[i].createTime,
            });
          }
        });
    },
    pageDetail(id) {
      this.$router.push({
        path: `/changeBiddingDetails?id=${id}`,
      });
    },
  },
  computed: {
    bwLayout: function () {
      if (
        this.wbiddingPagination.currentPages === 1 ||
        this.wbiddingPagination.currentPages === 0
      ) {
        return "pager, next";
      } else if (
        this.wbiddingPagination.currentPages ===
        Math.ceil(
          this.wbiddingData.totalPage / this.wbiddingPagination.pageSize
        )
      ) {
        return "prev, pager";
      } else {
        return "prev, pager, next";
      }
    },
  },
  created() {
    this.$store.state.navIndex = "/changeBidding";
    if (sessionStorage.getItem("detailC")) {
      //如果有就读取缓存里面的数据
      this.wbiddingPagination.currentPages = Number(
        sessionStorage.getItem("currentPageC")
      );
    } else {
      //第一次进入，清除缓存，清掉缓存里面的数据
      this.wbiddingPagination.currentPages = 1;
      sessionStorage.removeItem("currentPageC");
    }

    //关键字session
    if (sessionStorage.getItem("ifInputC")) {
      //如果有就读取缓存里面的数据
      this.inputkey = sessionStorage.getItem("inputkeyC");
      //取消判断
      sessionStorage.setItem("ifInputC", false);
    } else {
      //第一次进入，清除缓存，清掉缓存里面的数据
      this.inputkey = "";
    }
  },
  mounted() {
    //发送第一页请求
    axios
      .get("/api/bulletin/list", {
        params: {
          pageNo: this.wbiddingPagination.currentPages,
          pageSize: this.wbiddingPagination.pageSize,
          search: this.inputkey,
          type: "变更公告",
        },
      })
      .then((res) => {
        let list = res.data.content.list;
        this.wbiddingData.totalPage = parseInt(res.data.content.total);
        for (let i = 0; i < list.length; i++) {
          this.wbiddingData.wbiddingDatas.push({
            pid: list[i].id,
            ptitle: list[i].title,
            creationTime: list[i].createTime,
          });
        }
      });
  },
  beforeCreate() {
    if (sessionStorage.getItem("ifInputC") != "true") {
      sessionStorage.removeItem("ifInputC");
    }
  },
};
</script>


<style scoped>
.wbidding-page {
  margin: 0 auto;
}
.wbidding-main {
  margin-left: 2%;
}
/* .breadcrumb a {
  color: rgb(138, 138, 138);
  padding-left: 0.5rem;
}
.breadcrumb a:hover {
  color: rgb(138, 138, 138);
}
.breadcrumb-font {
  color: rgb(51, 51, 51);
  font-weight: 300;
} */
.wbidding-header {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  margin-bottom: 3rem;
  height: 2.5rem;
  border-bottom: 0.15rem solid rgb(189, 12, 21);
}
.bid-search .el-input {
  width: 250px;
}
.bid-search .el-input >>> .el-input__inner {
  border: 1px solid rgb(153, 30, 38);
}

.header-big {
  color: rgb(189, 12, 21);
  font-size: 1.5rem;
}
.header-small {
  color: rgb(102, 102, 102);
}
.wbidding-main-a {
  display: inline-block;
  height: 3rem;
  width: 100%;
  font-size: 1.1rem;
  color: rgb(51, 51, 51);
  text-decoration: none;
  border-bottom: 0.1rem solid rgb(238, 238, 238);
}
.wbidding-main-a span {
  line-height: 3rem;
}
.wbidding-main-time {
  margin-bottom: 1rem;
  height: 3rem;
  font-size: 0.8rem;
  color: rgb(136, 136, 136);
}
.wbidding-main-time span {
  line-height: 2rem;
}
.overhide {
  width: 50%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.wbidding-footer {
  text-align: center;
}
/* .wbidding-footer >>> button,
.wbidding-footer >>> .el-pager li {

} */

.wbidding-footer
  >>> .el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: #bd0c15;
  color: #ffffff;
}
.wbidding-footer
  >>> .el-pagination.is-background
  .el-pager
  li:not(.disabled):hover {
  color: #bd0c15;
}
</style>