<template>
    <div class="contact">
        <div class="nav">
            <span class="claim">联系我们/</span>
            <span class="announcement">Contact</span>
        </div> 
        <div class="contact-left">
            <baidu-map class="map" :center="center" :zoom="zoom" :scroll-wheel-zoom="true" @ready="handler" />
        </div>
        <div class="contact-right">
            <div class="contact-us">联系我们</div>
            <div class="contact-message">联系人：{{info.person}}</div>
            <div class="contact-message">联系电话：{{info.phoneNumber}}</div>
            <div class="contact-message">QQ客服：{{info.QQ}}</div>
            <div class="contact-message">电子邮箱：{{info.email}}</div>
            <div class="contact-message">公司地址：{{info.address}}</div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name:"contact",
    data() {
		return {
			center: { lng: 0, lat: 0 },
			zoom: 0,
            info:{
                person:'刘悦、王雯靓',
                phoneNumber:'0791-83831221',
                QQ:'912825842@qq.com',
                email:'JXJY9418@163.com',
                address:'江西省南昌市洪城路国贸广场A座巨豪峰2701室'
            }
		}
	},
	created(){
        this.$store.state.navIndex = '/contact',
        axios.get('http://106.55.230.35:8899/info/list').then(
            res=>{
                // console.log(res.data.content)
                let message = res.data.content
                this.info.person = message[6].content
                this.info.phoneNumber = message[1].content
                this.info.QQ = message[7].content
                this.info.email = message[0].content
                this.info.address = message[8].content
            }
        )
    },
	mounted() {},
	methods: {
		handler({ BMap, map }) {
			this.center.lng = 115.914701
			this.center.lat = 28.660501
			this.zoom = 15
            // 创建点标记
            var marker1 = new BMap.Marker(new BMap.Point(115.914701, 28.660501));
            // 在地图上添加点标记
            map.addOverlay(marker1);
		},
	}

}
</script>

<style scoped>
    .nav {
    position: relative;
    /* margin-top: 3rem; */
    /* margin-left: 3rem; */
    margin-bottom: 1.5rem;
    /* height: 2.5rem; */
    border-bottom: 0.15rem solid rgb(189, 12, 21);
    }
    .claim {
        color: rgb(189, 12, 21);
        font-size: 25px;
    }
    .announcement {
        color: rgb(102, 102, 102);
        font-size: 16px;
    }
    .map {
    width: 35rem;
	height: 25rem;
    }
    .contact-left{
        float: left;
        margin-top: 1rem;
    }
    .contact-right {
        float: right;
        /* margin-right: 10rem; */
    }
    .contact-us {
        margin-top: 4rem;
        margin-bottom: 3rem;
        font-size: 20px;
        font-weight: 600;
    }
    .contact-message {
        margin-top: 2rem;
        margin-bottom: 2rem;
        font-size: 17px;
        font-weight: 550;
    }

</style>
