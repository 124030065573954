import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    navIndex:'/',
    zhaoCurrentPage:1,
    zhongCurrentPage:1,
    xiaCurrentPage:1,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
