<template>
  <div class="introduce-cpn">
    <div class="introduce-cpn-header">
      <span class="common-header-big">公司简介/</span>
      <span class="common-header-small">introduce</span>
      <span class="header-cpn-right"><a href="/companyProfile">>></a></span>
    </div>
    <div class="introduce-cpn-main common-main">
      <!-- <el-image
        style="width: 100%; height: 200px"
        src=""
        fit="fit"
      ></el-image> -->
      <img
        :src="imgSrc"
        alt=""
        style="width: 100%; height: 200px"
      />
      <p>
        {{content}}
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios"
export default {
  name: "",
  data() {
    return {
      imgSrc :"",
      content:'',
    };
  },
  mounted() {
    axios.get("/api/image/list?category=7").then((res) => {
      this.imgSrc = `http://106.55.230.35${res.data.content[2].url}`;
    });
  },
  created(){
    axios.get("/api/info/list").then((res) => {
      this.content = res.data.content[9].content;
    });
  }
};
</script>

<style scoped>
.introduce-cpn-header {
  position: relative;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  height: 2.5rem;
  border-bottom: 0.15rem solid rgb(189, 12, 21);
}

.header-cpn-right {
  position: absolute;
  right: 0;
  top: 8px;
}
.header-cpn-right a {
  color: rgb(189, 12, 21);
}
.header-cpn-right a:hover {
  color: rgb(102, 102, 102);
}
.introduce-cpn-main {
  padding: 10px 0;
}
.introduce-cpn-main p {
  color: rgb(75, 73, 73);
  text-indent: 1rem;
}
</style>