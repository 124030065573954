<template>
  <el-carousel height="600px">
    <el-carousel-item v-for="item in SlideImgData" :key="item.src">
       <el-image :src="item.src" style="width: 100%;height: 100%;" fit="cover"></el-image>
       <div class="slider-detail">
         <div class="slider-center">
           <p class="center-p1">{{text.textinfo1}}</p>
           <p class="center-p2">{{text.textinfo2}}</p>
         </div>
       </div>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
import axios from "axios"
export default {
  data(){
    return {
      SlideImgData : [],
      text:{
        textinfo1 : "",
        textinfo2 : ""
      }
    }
  },
  mounted(){
    axios.get("/api//info/list").then(res => {
      this.text.textinfo1 = res.data.content[3].content;
      this.text.textinfo2 = res.data.content[4].content;
    })


    axios.get("/api/image/list?category=1").then((res) => {
      this.SlideImgData.splice(0, this.SlideImgData.length)
      for(let i = 0; i < res.data.content.length; i++){
        this.SlideImgData.push({
          src : `http://106.55.230.35${res.data.content[i].url}`
        })
      }
    })
  }
};
</script>

<style scoped>
p{
  margin: 0;
  padding: 0;
}
.el-carousel-item{
  position: relative;
}
.slider-detail{
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<style scoped>
.slider-detail{
  width:100%;
  height: 100%;
  color: black;
  text-shadow: 1px 1px 1px white;
}
.el-image{
  object-fit: cover;
}
.slider-center{
  padding: 30px 90px;
  background-color: rgba(0, 0, 0, 0.3);
}
.slider-center .center-p1{
  text-align: center;
  font-size: 40px;
  padding: 20px 10px;
  color: rgb(240, 238, 238);
  border-bottom: 1px solid rgb(240, 238, 238);
}
.slider-center .center-p2{
  text-align: center;
  font-size: 20px;
  padding: 20px 10px;
  color: rgb(240, 238, 238);
}
</style>