<template>
  <div class="notice">
    <div class="nav">
      <div class="claim">澄清变更公告/</div>
      <div class="announcement">Announcement</div>
    </div> 
    <div class="message">
      <div  v-for="item in items" :key="item.id">
        <a :href="item.url" class="message-box">{{item.message}}</a>
        <div class="time">{{item.time}}</div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="block">
      <el-pagination
        background
        :layout="getLayout"
        :current-page="currentpage"
        :total="totalpages"
        :page-size="pageSize"
        @current-change="currentChange">
      </el-pagination>
    </div>

  </div>
</template>

<script>

export default {
  name: 'notice',
  data(){
    return{
        pageSize: 4,
        currentpage:1,
        totalpages:25,
        items: [
        { 
          id:1,
          message: '南昌市新建区教育体育局属学校集体用餐配送服务外包项目(采购编号：JXYJ...',
          time:'2021-09-08 10:43:34',
          url:'http://www.jxyjzb.com/219383-219383.html?newsid=1135849&_t=1631069121',
        },
        { 
          id:2,
          message: '南昌市第二十四中学集体用餐配送服务外包项目(采购编号：JXYJ2021-...',
          time:'2021-08-20 15:37:43',
          url:'http://www.jxyjzb.com/219383-219383.html?newsid=1181039&_t=1635320396',
        },
        { 
          id:3,
          message: '南昌市新建区教育体育局属学校集体用餐配送服务外包项目(采购编号：JXYJ...',
          time:'2021-07-14 16:47:31',
          url:'http://www.jxyjzb.com/219383-219383_1068565.html',
        },
        { 
          id:4,
          message: '南昌市新建区教育体育局属学校集体用餐配送服务外包项目(采购编号：JXYJ...',
          time:'2021-07-08 11:35:11',
          url:'http://www.jxyjzb.com/219383-219383_1059243.html',
        },
      ],
      }
  },
  methods: {
    currentChange(index) {
      this.currentpage = index;
    },
  },
  computed:{
    getLayout: function () {
      if (this.currentpage === 1) {
        return "pager, next";
      } else if (this.currentpage === Math.ceil(this.totalpages / this.pageSize)) {
        return "prev, pager";
      }
      else {
        return "prev, pager, next";
      }
    },
  },
}
</script>

<style scoped>
  .notice {
    margin: 0 auto;
  }
  .nav {
    margin-top: 3rem;
    height: 2.5rem;
    border-bottom: 0.15rem solid rgb(189, 12, 21);
  }
  .claim {
    display: inline-block;
    font-size: 1.7rem;
    font-weight: 400;
    color: rgb(189,12,21);
  }
  .announcement {
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
    color: rgb(102, 102, 102);
  }
  .message-box {
    width: 79%;
    padding-bottom: 0.8rem;
    margin-top: 2.5rem;
    border-bottom: 0.1px solid rgb(212, 211, 211);
  }
  .message {
    margin-top: 0.5rem;
    margin-left: 1rem;
    width: 100%;
  }
  .message a {
    display: inline-block;
    text-decoration:none;
    font-size: 1.1rem;
    font-weight: 350;
    color: black;
  }
  .time {
    margin-top: 0.6rem;
    color: rgb(136, 136, 136);
  }


  /* 分页样式 */
  
  .block {
    text-align: center;
    margin-top: 2rem;
  }

.block >>> button,
.block >>> .el-pager li {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1rem;
  font-weight: 400;
  background-color: transparent !important;
  color: #131314 !important;
  border: 1px solid #d1d3d4;
  line-height: 2.4rem;
}

.block >>> .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgb(189,12,21) !important;
  color: white !important;
}





</style>
